<template>
  <b-modal
    v-model="showModal"
    title="RG Report Details"
    no-close-on-backdrop
    size="lg"
    ok-title="Update"
    scrollable
    @ok="okHandler"
    @close="$emit('modal-closed')"
    @hidden="$emit('modal-closed')"
  >
    <b-alert
      variant="danger"
      :show="errorMessage ? true : false"
    >
      <div
        v-if="typeof errorMessage === 'object'"
        class="alert-body"
      >
        <p
          v-for="(message, index) in errorMessage"
          :key="index"
        >
          {{ message }}
        </p>
      </div>
      <p
        v-else
        class="alert-body"
      >
        {{ errorMessage }}
      </p>
    </b-alert>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>
    <div v-if="!loading && !errorMessage">
      <b-table-simple
        responsive
        hover
        class="compact-table"
      >
        <b-tbody>
          <b-tr>
            <b-th> Bill No. </b-th>
            <b-td> {{ rgReportDetails.invoice__bill_no }} </b-td>
            <b-th> Rack Group </b-th>
            <b-td>{{ rgReportDetails.rack_group_no }}</b-td>
            <b-th> Status </b-th>
            <b-td> {{ rgReportDetails.status }} </b-td>
          </b-tr>
          <b-tr>
            <b-th> No. of Items </b-th>
            <b-td colspan="5">
              {{ rgReportDetails.items_count }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th> Pickup Initiated At </b-th>
            <b-td>
              {{ rgReportDetails.pickup_initiated_at && formatedDate(rgReportDetails.pickup_initiated_at, 'DD-MM-YYYY LT') || '-' }}
            </b-td>
            <b-th> Pickup Initiated By </b-th>
            <b-td colspan="3">
              {{ rgReportDetails.pickup_initiated_by__name }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th> Pickup Completed At </b-th>
            <b-td colspan="5">
              {{ rgReportDetails.pickup_completed_at && formatedDate(rgReportDetails.pickup_completed_at, 'DD-MM-YYYY LT') || '-' }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th> Pickup Verified At </b-th>
            <b-td>
              {{ rgReportDetails.pickup_verified_at && formatedDate(rgReportDetails.pickup_verified_at, 'DD-MM-YYYY LT') || '-' }}
            </b-td>
            <b-th> Pickup Verified By </b-th>
            <b-td colspan="3">
              {{ rgReportDetails.pickup_verified_by__name }}
            </b-td>
          </b-tr>
          <b-tr v-if="rgReportDetails.action_taken_by__name">
            <b-th> Action Completed By </b-th>
            <b-td>
              {{ rgReportDetails.action_taken_by__name || '' }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <b-form-group label="Remarks">
        <b-form-textarea
          v-model="rgReportDetails.remarks"
          rows="3"
          type="text"
          placeholder="Remarks"
          :disabled="readOnly"
        />
      </b-form-group>
      <b-form-group>
        <b-form-checkbox
          v-if="rgReportDetails.attention_required === true"
          v-model="attention_closed"
          :disabled="readOnly"
        >
          Action taken for revision
        </b-form-checkbox>

        <span
          v-else-if="rgReportDetails.attention_required === false"
          class="text-primary"
        >
          Action completed for revision
        </span>
      </b-form-group>
      <b-form-group>
        <b-form-select
          v-if="rgReportDetails.attention_required === true"
          v-model="selectedUserId"
          :options="[
            { value: '', text: 'Action Completed by', disabled: true },
            ...operationUsers.map(user => ({value: user.id, text: user.name}))
          ]"
        />
      </b-form-group>
    </div>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Close
      </b-button>
      <b-button
        v-if="!readOnly"
        variant="primary"
        :disabled="submitting"
        @click="ok()"
      >
        Send
        <b-spinner
          v-if="submitting"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BSpinner, BButton, BAlert, BTableSimple, BTr, BTh, BTd, BTbody, BFormTextarea, BFormGroup, BFormCheckbox,
  BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import dateMixin from '@/mixins/dateMixin'

export default {
  components: {
    BModal,
    BButton,
    BSpinner,
    BAlert,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BTbody,
    BFormTextarea,
    BFormGroup,
    BFormCheckbox,
    BFormSelect,
  },
  mixins: [dateMixin],
  props: {
    id: { type: Number, required: true },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
    operationUsers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
      rgReportDetails: {},
      loading: true,
      submitting: false,
      selectedUserId: '',
      attention_closed: false,
      errorMessage: '',
    }
  },
  mounted() {
    this.fetchRGReportDetails()
  },
  methods: {
    async fetchRGReportDetails() {
      try {
        this.loading = true
        const { data } = await axios.get(`/operations/rack_group_reports/${this.$props.id}/`)
        this.rgReportDetails = data
      } catch (error) {
        this.errorMessage = error?.response?.data?.detail || 'Failed to fetch details'
      } finally {
        this.loading = false
      }
    },
    async okHandler(event) {
      event.preventDefault()
      this.submitting = true
      try {
        const paylaod = { remarks: this.rgReportDetails.remarks, action_taken_by: this.selectedUserId }
        if (this.attention_closed) {
          paylaod.attention_required = false
        }
        await axios.patch(`/operations/rack_group_reports/${this.$props.id}/`, paylaod)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Information Updated',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$emit('modal-closed')
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.detail || 'Failed to fetch details!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.submitting = false
      }
    },
  },
}
</script>
