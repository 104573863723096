<template>
  <b-modal
    v-model="modalShow"
    size="sm"
    centered
    :title-html="`Initiate packing${note ? `  <span style='color: #f23d3d; font-weight: bold;'>( ${note} )</span> ` : ''}`"
    @hidden="$emit('modal-closed')"
  >
    <div>
      <b-form @submit.prevent="submitHandler">
        <b-form-group
          label="User"
        >
          <custom-v-select
            v-model="selectedUserId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="operationUsers"
            label="name"
            placeholder="Select User"
            :reduce="(item) => item.id"
          />
        </b-form-group>
      </b-form>

      <b-alert
        variant="danger"
        :show="errorMessage ? true : false"
      >
        <div class="alert-body">
          <p>
            {{ errorMessage }}
          </p>
        </div>
      </b-alert>
    </div>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="!selectedUserId || submitting"
        @click="submitHandler"
      >
        Submit
        <b-spinner
          v-if="submitting"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton, BSpinner, BForm, BFormGroup, BAlert,
} from 'bootstrap-vue'
import axios from 'axios'

import downloadFileMixin from '@/mixins/downloadFileMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'

export default {
  components: {
    BButton,
    BSpinner,
    BFormGroup,
    BForm,
    CustomVSelect,
    BAlert,
  },
  mixins: [downloadFileMixin],
  props: {
    operationUsers: {
      type: Array,
      required: true,
    },
    billRgStatusIds: {
      type: Array,
      required: true,
    },
    note: {
      type: String,
      default: '',
    },
    isAutoPrintAllowed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalShow: true,
      submitting: false,
      selectedUserId: null,
      errorMessage: null,
      pdfUrls: [],
    }
  },
  mounted() {
    this.fetchRGReportDetails()
  },
  methods: {
    async fetchRGReportDetails() {
      try {
        this.loading = true
        const { data } = await axios.get(`/operations/bill_rack_group_status/${this.billRgStatusIds[0]}/`)
        const urls = data.bill_attachments?.map(item => item.file)
        this.pdfUrls = urls?.slice(-1)
      } catch (error) {
        this.errorMessage = error?.response?.data?.detail || 'Failed to fetch details'
      } finally {
        this.loading = false
      }
    },
    submitHandler() {
      this.submitting = true
      axios.post('/operations/initiate-packing', {
        bill_rg_status_ids: this.billRgStatusIds,
        operation_user_id: this.selectedUserId,
      })
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          this.$emit('updated')
          this.modalShow = false
          if (this.isAutoPrintAllowed) {
            this.openUrlsInNewTabs()
          }
        }).catch(error => {
          this.errorMessage = error?.response?.data?.detail || 'Failed to update status!'
          this.submitting = false
        })
    },
    openUrlsInNewTabs() {
      // Open each URL in a new window with a delay to avoid popup blockers
      if (this.pdfUrls && this.pdfUrls.length) {
        // Create a shallow copy of the array to avoid issues with async operations
        const urls = [...this.pdfUrls]

        // Open URLs one by one with a slight delay
        urls.forEach((url, index) => {
          setTimeout(() => {
            window.open(url, `pdf_window_${index}`, 'noopener,noreferrer,width=800,height=600')
          }, index * 300) // 300ms delay between each window
        })
      }
    },
  },
}
</script>
