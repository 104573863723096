<template>
  <div>
    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>
    <b-row
      v-if="!loadingError"
    >
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group label="Bill No.">
          <b-form-input
            v-model="billNo"
            type="text"
            placeholder="Search Bill No."
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Area"
        >
          <custom-v-select
            v-model="filters.areas"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="areaOptions"
            label="text"
            placeholder="Select Areas"
            multiple
            :disabled="loading"
            :close-on-select="false"
            :reduce="data => data.id"
            :deselect-from-dropdown="true"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group label="Buyers">
          <custom-v-select
            v-model="filters.buyers"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="buyerOptions"
            label="text"
            placeholder="Select Buyers"
            multiple
            :disabled="loading"
            :close-on-select="false"
            :reduce="data => data.id"
            :deselect-from-dropdown="true"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group label="Bill Created By">
          <custom-v-select
            v-model="filters.createdBy"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="margUsers"
            label="name"
            placeholder="Select Created By"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
            :reduce="(data => data.name)"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="Bill Date"
        >
          <flat-picker
            v-model="filters.date"
            placeholder="Select Date Range"
            :config="{ mode: 'range'}"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group label="Bill Status">
          <custom-v-select
            v-model="filters.billStatuses"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="billStatusOptions"
            label="text"
            placeholder="Select Bill Status"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group label="Bill Urgent Status">
          <custom-v-select
            v-model="filters.billUrgentStatuses"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="billUrgentStatusOptions"
            label="text"
            placeholder="Select Bill Urgent Status"
            :reduce="(item) => item.value"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group label="Rack Group">
          <custom-v-select
            v-model="filters.rackGroups"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="rackGroupOptions"
            label="text"
            placeholder="Select Rack Groups"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
            :disabled="loading"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group label="Rack Group Status">
          <custom-v-select
            v-model="filters.rackGroupStatuses"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="rackGroupStatusOptions"
            label="text"
            placeholder="Select Rack Group Status"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group label="Bucket No.">
          <b-form-input
            v-model="bucketNo"
            type="text"
            placeholder="Search Bucket No."
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-checkbox
          v-model="filters.attentionRequired"
          style="margin-top: 0.5rem"
        >
          Attention Required
        </b-form-checkbox>
        <b-form-checkbox
          v-model="filters.readyForPacking"
          style="margin-top: 0.5rem"
        >
          Ready For Packing
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group label="Item Name">
          <custom-v-select
            v-model="filters.products"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="productOptions"
            label="text"
            placeholder="Select Item Name"
            multiple
            :close-on-select="false"
            :reduce="data => data.value"
            :deselect-from-dropdown="true"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-checkbox
          v-model="filters.is_modified"
          style="margin-top: 1.5rem"
        >
          Modified
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BFormGroup, BAlert, BFormCheckbox,
} from 'bootstrap-vue'
import axios from 'axios'
import { debounce } from 'lodash'

import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import FlatPicker from '@/components/UI/FlatPicker.vue'

import { BILL_RG_STATUS_STATUS_OPTIONS } from '@/utils/consts'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    CustomVSelect,
    BAlert,
    FlatPicker,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    rackGroupOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      areaOptions: [],
      buyerOptions: [],
      productOptions: [],
      margUsers: [],
      loadingError: null,
      loading: true,
      rackGroupStatusOptions: ['Created', 'Pickup Initiated', 'Pickup Completed', 'Pickup Verified'],
      billStatusOptions: Object.keys(BILL_RG_STATUS_STATUS_OPTIONS).map(itemKey => BILL_RG_STATUS_STATUS_OPTIONS[itemKey]),
      billUrgentStatusOptions: [{ text: 'Urgent', value: 'urgent' }, { text: 'Non Urgent', value: 'non-urgent' }],
    }
  },
  computed: {
    filters() {
      return this.$props.value
    },
    billNo: {
      get() {
        return this.filters.bill_no
      },
      set(newVal) {
        this.updateBillNo(newVal)
      },
    },
    bucketNo: {
      get() {
        return this.filters.bucket_no
      },
      set(newVal) {
        this.updateBucketNo(newVal)
      },
    },
  },
  mounted() {
    this.fetchOptions()
  },
  methods: {
    updateBillNo: debounce(function updateData(data) {
      this.$emit('input', {
        ...this.filters,
        bill_no: data,
      })
    }, 500),
    updateBucketNo: debounce(function updateData(data) {
      this.$emit('input', {
        ...this.filters,
        bucket_no: data,
      })
    }, 500),

    async fetchOptions() {
      this.loading = true
      try {
        const { data: areas } = await axios.get('/accounts/areas_options')
        this.areaOptions = areas.data

        const { data: buyers } = await axios.get('/accounts/buyers_options')
        this.buyerOptions = buyers.data

        const { data: products } = await axios.get('/item_options')
        const updatedProduct = products.data.map(item => {
          const updatedTextAry = item.text.split(' ')
          let finalText = ''
          if (updatedTextAry.length >= 0) {
            finalText = updatedTextAry.slice(0, -1).join(' ')
          } else {
            finalText = updatedTextAry.join(' ')
          }
          return { id: item.id, text: finalText, value: finalText }
        })
        this.productOptions = updatedProduct

        const { data: margUsers } = await axios.get('/accounts/marg_users')
        this.margUsers = margUsers
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Failed to fetch filter options!'
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
